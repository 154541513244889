<template>
  <v-container>
    <v-tabs v-model="selectedTab">
      <v-tab>ХИЧЭЭЛЭЭР</v-tab>
      <v-tab> СУРАЛЦАГЧААР </v-tab>
      <v-tab>НЭГТГЭЛ</v-tab>
      <v-tab>ХИЧЭЭЛИЙН ДҮН ТАТАХ</v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab">
      <v-tab-item>
        <v-btn class="mt-4 bg-gradient-info" dark>
          Судлагдахууны дүн бодох
        </v-btn>
        <v-data-table
          :headers="headers"
          :items="courses"
          class="elevation-1"
          dense
          hide-details
          hide-default-footer
          :items-per-page="-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Хичээл</v-toolbar-title>
            </v-toolbar>
          </template>
        </v-data-table></v-tab-item
      >
      <v-tab-item>
        <v-btn class="mt-4 bg-gradient-info" dark>
          Судлагдахууны дүн бодох
        </v-btn>
        <v-data-table
          :headers="headers2"
          :items="students"
          class="elevation-1"
          dense
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Суралцагчийн мэдээлэл</v-toolbar-title>
            </v-toolbar>
          </template>
        </v-data-table></v-tab-item
      >
      <v-tab-item>
        3
      </v-tab-item>
      <v-tab-item
        >4
        <v-btn   outlined>
          Xичээл татаx
        </v-btn>
        <v-btn class="mt-4 bg-gradient-success" dark>
          Дүн оруулаx
        </v-btn>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      selectedTab: 0,
      headers: [
        { text: "Хичээл", value: "name", align: "start" },
        { text: "Төрөл", value: "type" },
        { text: "Нийт судалсан", value: "totalStudied" },
        { text: "Дүн батлагдсан", value: "gradeApproved" },
        { text: "Дүн бүртгэсэн", value: "gradeRegistered" },
        { text: "Хянагдаж байгаа", value: "underReview" },
        { text: "Дүн цуцлагдсан", value: "gradeCancelled" },
      ],
      courses: [
        {
          name: "Монгол хэл 12",
          type: "Заавал",
          totalStudied: 0,
          gradeApproved: 0,
          gradeRegistered: 0,
          underReview: 0,
          gradeCancelled: 0,
        },
        {
          name: "Үндэсний бичиг 12",
          type: "Заавал",
          totalStudied: 0,
          gradeApproved: 0,
          gradeRegistered: 0,
          underReview: 0,
          gradeCancelled: 0,
        },
        {
          name: "Уран зохиол 12",
          type: "Заавал",
          totalStudied: 0,
          gradeApproved: 0,
          gradeRegistered: 0,
          underReview: 0,
          gradeCancelled: 0,
        },
        {
          name: "Математик 12",
          type: "Заавал",
          totalStudied: 0,
          gradeApproved: 0,
          gradeRegistered: 0,
          underReview: 0,
          gradeCancelled: 0,
        },
        {
          name: "Мэдээллийн технологи 12",
          type: "Заавал",
          totalStudied: 0,
          gradeApproved: 0,
          gradeRegistered: 0,
          underReview: 0,
          gradeCancelled: 0,
        },
        {
          name: "Англи хэл 12",
          type: "Заавал",
          totalStudied: 0,
          gradeApproved: 0,
          gradeRegistered: 0,
          underReview: 0,
          gradeCancelled: 0,
        },
        {
          name: "Орос хэл 12",
          type: "Заавал",
          totalStudied: 0,
          gradeApproved: 0,
          gradeRegistered: 0,
          underReview: 0,
          gradeCancelled: 0,
        },
        {
          name: "Биеийн тамир 12",
          type: "Заавал",
          totalStudied: 0,
          gradeApproved: 0,
          gradeRegistered: 0,
          underReview: 0,
          gradeCancelled: 0,
        },
        {
          name: "Эрүүл мэнд 12",
          type: "Заавал",
          totalStudied: 0,
          gradeApproved: 0,
          gradeRegistered: 0,
          underReview: 0,
          gradeCancelled: 0,
        },
        {
          name: "Дизайн/зураг зүй, технологи 12",
          type: "Заавал",
          totalStudied: 0,
          gradeApproved: 0,
          gradeRegistered: 0,
          underReview: 0,
          gradeCancelled: 0,
        },
      ],

      headers2: [
        { text: "Суралцагч", value: "student", align: "start" },
        { text: "Регистр", value: "register" },
        { text: "Нийт судалсан", value: "totalStudied" },
        { text: "Бодогдсон", value: "calculated1" },
        { text: "Бодогдоогүй", value: "notCalculated1" },
        { text: "Бодогдсон", value: "calculated2" },
        { text: "Бодогдоогүй", value: "notCalculated2" },
        { text: "Бодогдсон", value: "calculated3" },
        { text: "Бодогдоогүй", value: "notCalculated3" },
      ],
      students: [
        {
          student: "Н.Бархасвад",
          register: "ме07322714",
          totalStudied: 91,
          calculated1: 0,
          notCalculated1: 17,
          calculated2: 0,
          notCalculated2: 38,
          calculated3: 0,
          notCalculated3: 17,
        },
        {
          student: "М.Ванчир-Эрдэнэ",
          register: "ме07241811",
          totalStudied: 91,
          calculated1: 0,
          notCalculated1: 17,
          calculated2: 0,
          notCalculated2: 38,
          calculated3: 0,
          notCalculated3: 17,
        },
        {
          student: "Б.Гансух",
          register: "ру07272316",
          totalStudied: 91,
          calculated1: 0,
          notCalculated1: 17,
          calculated2: 0,
          notCalculated2: 38,
          calculated3: 0,
          notCalculated3: 17,
        },
        {
          student: "У.Жавхлан",
          register: "тя07252311",
          totalStudied: 91,
          calculated1: 0,
          notCalculated1: 17,
          calculated2: 0,
          notCalculated2: 38,
          calculated3: 0,
          notCalculated3: 17,
        },
        {
          student: "Э.Мичидмаа",
          register: "ме07231700",
          totalStudied: 91,
          calculated1: 0,
          notCalculated1: 17,
          calculated2: 0,
          notCalculated2: 38,
          calculated3: 0,
          notCalculated3: 17,
        },
        {
          student: "Д.Мягмарсүх",
          register: "ме07280709",
          totalStudied: 91,
          calculated1: 0,
          notCalculated1: 17,
          calculated2: 0,
          notCalculated2: 38,
          calculated3: 0,
          notCalculated3: 17,
        },
        {
          student: "Т.Мөнхтулга",
          register: "ме07312717",
          totalStudied: 91,
          calculated1: 0,
          notCalculated1: 17,
          calculated2: 0,
          notCalculated2: 38,
          calculated3: 0,
          notCalculated3: 17,
        },
        {
          student: "Б.Мөнхцэцэг",
          register: "ме07292605",
          totalStudied: 91,
          calculated1: 0,
          notCalculated1: 17,
          calculated2: 0,
          notCalculated2: 38,
          calculated3: 0,
          notCalculated3: 17,
        },
        {
          student: "Н.Мөрөн",
          register: "ме07292514",
          totalStudied: 91,
          calculated1: 0,
          notCalculated1: 17,
          calculated2: 0,
          notCalculated2: 38,
          calculated3: 0,
          notCalculated3: 17,
        },
        {
          student: "Э.Нандин-Эрдэнэ",
          register: "ме07222604",
          totalStudied: 91,
          calculated1: 0,
          notCalculated1: 17,
          calculated2: 0,
          notCalculated2: 38,
          calculated3: 0,
          notCalculated3: 17,
        },
      ],
    };
  },
};
</script>
