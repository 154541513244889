<template>
  <v-card>
    <div class="pa-4 pb-0">
      <!-- <h6 class="text-h6 text-typo font-weight-bold">Xүйсийн xарьцаа</h6> -->
    </div>
    <div class="card-padding">
      <div class="chart">
        <canvas
          :id="'doughnut-chart' + $attrs.chartId"
          class="chart-canvas"
          height="255"
        ></canvas>
      </div>
    </div>
  </v-card>
</template>
<script>
import Chart from "chart.js/auto";

export default {
  name: "doughnut-chart",
  data: function () {
    return {
      doughnutChartId: "doughnut-chart",
    };
  },
  mounted() {
    var xChart = document.getElementById(this.doughnutChartId+this.$attrs.chartId);
    if (xChart) {
      new Chart(xChart.getContext("2d"), {
        type: "doughnut",
        data: {
          labels: ["Батлагдсан", "Шийдвэрлэгдээгүй"],
          datasets: [
            {
              label: "Projects",
              weight: 9,
              cutout: 50,
              tension: 0.9,
              pointRadius: 2,
              borderWidth: 2,
              backgroundColor: ["#4ADE80", "#FADE21"],
              data: [300, 350],
              fill: false,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "top",
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
            },
          },
        },
      });
    }
  },
};
</script>
