<template>
  <v-container fluid class="px-0">
    <v-row>
      <v-col cols="12" md="3" v-for="(chart, index) in charts" :key="index">
        <v-card  class="card-shadow border-radius-xl">
          <v-card-title class="text-h6 text-typo pb-2">{{
            chart.title
          }}</v-card-title>
          <v-divider
            style="
              height: 40px!important;
              width: 80%!important;
              background-color: grey
            "
          ></v-divider>
          <v-card-text>
            <ChartW :chartId="index"></ChartW>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ChartW from "./ChartW.vue";
export default {
  data() {
    return {
      selectedTab: 0,
      charts: [
        { title: "Ээлжит хичээлийн дүн", approved: 210, pending: 32 },
        { title: "Хичээлийн дүн шилжэлт", approved: 512, pending: 160 },
        { title: "Судалгааны дүн", approved: 0, pending: 256 },
        { title: "Шалгалт", approved: 0, pending: 64 },
        { title: "Төгсөлтийн бүртгэл", approved: 0, pending: 16 },
        { title: "Баримт бичиг олголт", approved: 0, pending: 0 },
        { title: "Элсэлтийн бүртгэл", approved: 0, pending: 0 },
      ],
    };
  },
  components: {
    ChartW,
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
</style>
